<template>
	<div class="check">
		<PageTitle title="我的账单"
               :backTo="(this.$route.params.pd == '0') ?this.$router.go(1): '/self'"
               :leftArrow="(this.$route.params.pd == '0') ? true : false"></PageTitle>
		<List>
			<ListItem class="cardInfo" style="margin-top: 20px;padding-top: 2px" :class="[isActive5]">
				<template #top>
					<div style="text-align: center;padding-top: 5px">
						<p><span>就诊卡：{{ cardInfo.brxm }}</span><span>{{ cardInfo.xzmc }}</span><span>{{ cardInfo.ckhm | filterCardNum }}</span><span>
								<router-link to="/accountJudgment/0/0/0?backPath=check">
									<van-icon name="idcard" size="16px" />卡包
								</router-link>
							</span></p>
					</div>
				</template>
			</ListItem>
			<ListItem style="margin-top: 20px">
				<template v-slot:top style="box-shadow: 0 0 5px 0 #DEDEDE;">
					<div style="overflow: scroll;overflow-x: hidden;border-top: none" :style="{height:GD1}">
						<p style="text-align: center;padding-top: 10px">账单</p>
						<div class="borderColor" v-for="(item,index) in items" :key="index" style="margin-top: 10px;">
							<div class="leftArea">
								<p class="fontmd">{{ items[index].jfje==0?"消费时间：":"交易时间："}}{{item.rq.replace("T"," ")}}
								</p>
								<p class="fontmd">
									{{ items[index].jfje==0?"消费金额：":"交易金额："}}{{items[index].jfje==0?items[index].dfje:items[index].jfje}}
								</p>
								<p class="fontmd">{{ items[index].jfje==0?"消费名称：":"交易名称："}}{{item.zy}}</p>
							</div>
						</div>
						<div class="bottomAdd" style="text-align: center">
							<p>注: 账户充值记录显示为三个月以内</p>
							<p>准确的数据以医院提供的票据为准</p>
							<p>支付成功:充值金额未到账,请联系收费处</p>
							<p style="margin-bottom: 10px">到账成功:充值金额已到账</p>
						</div>
					</div>
					<div :class="[isActive4]" style="overflow: scroll;overflow-x: hidden;" :style="{height:GD}">
						<p style="text-align: center;padding-top: 10px ">充值账单</p>
						<div class="borderColor" v-for="(item,index) in items" v-show="items[index].jfje!=0"
							:key="index" style="margin-top: 10px">
							<div class="leftArea">
								<p class="fontmd">交易时间：{{item.rq.replace("T"," ")}}</p>
								<p class="fontmd">交易金额：{{item.jfje}}</p>
								<p class="fontmd">交易状态：{{item.zy}}</p>
							</div>
						</div>
						<div class="bottomAdd" style="text-align: center">
							<p>注: 账户充值记录显示为三个月以内</p>
							<p>准确的数据以医院提供的票据为准</p>
							<p>支付成功:充值金额未到账,请联系收费处</p>
							<p style="margin-bottom: 10px">到账成功:充值金额已到账</p>
						</div>
					</div>
				</template>
			</ListItem>
		</List>
	</div>
</template>


<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		GetBill
	} from '@/api/check.js';
	import {
		getDefaultAccount
	} from '@/api/user.js';
	import {
		Dialog
	} from 'vant';
	export default {
		name: 'Check',
		components: {
			PageTitle,
			List,
			ListItem,
		},
		created: function() {
			this.GetXFZD()
			this.hh();
		},
		data() {
			return {
				isActive1: "button1",
				isActive2: "button2",
				isActive3: "p2",
				isActive4: "p1",
				isActive5: "p2",
				items: "",
				cardInfo: {
					address: null,
					birthday: null,
					brxb: "",
					brxm: "",
					brxz: "",
					ckhm: "",
					id: 0,
					ktye: 0,
					mrzh: null,
					mz: null,
					namepb: null,
					nl: null,
					phonePB: null,
					sfzh: "",
					sfzpb: null,
					sjhm: null,
					srbrxm: null,
					srphone: null,
					srsfzh: null,
					xzmc: "",
					zhye: 0,
					zhyxpb: null
				},
				ckhm: "",
				GD: "",
				GD1: "",
				width: {
					width: '',
				}

			}
		},
		methods: {
			GetXFZD() {
				this.isActive1 = 'button1';
				this.isActive2 = 'button2';
				this.isActive3 = 'p2';
				this.isActive4 = 'p1';
				setTimeout(() => {

				}, 1000)
			},
			GetCZZD() {
				this.isActive1 = 'button3';
				this.isActive2 = 'button4';
				this.isActive3 = 'p1';
				this.isActive4 = 'p2';
				GetBill(this.ckhm).then(
					(result) => {
						this.items = result;
					},
					() => {}
				);
			},
			hh() {
				this.GD = document.documentElement.clientHeight - 260 + 'px';
				this.GD1 = document.documentElement.clientHeight - 220 + 'px';
				this.width.width = document.documentElement.clientWidth * 0.425 + 'px'
				if (this.$route.params.ckhm == "0") {
					getDefaultAccount(this.$store.getters.userInfo.UserId).then(
						(result) => {
							this.cardInfo = result;
							this.ckhm = result.ckhm;
							GetBill(this.ckhm).then(
								(result) => {
									this.items = result;
								},
								() => {}
							);
						},
						() => {}
					);
					setTimeout(() => {
						if (this.ckhm == "") {
							Dialog.alert({
								message: '未找到默认账户信息，请先设置',
							}).then(() => {
								// on clo
								this.$router.push('/accountJudgment/0/0/0?backPath=check')
							});
						}
					}, 1000)
				} else {
					this.ckhm = this.$route.params.ckhm;
					GetBill(this.ckhm).then(
						(result) => {
							this.items = result;
						},
						() => {}
					);
					this.cardInfo.ckhm = this.$route.params.ckhm;
					this.cardInfo.brxm = this.$route.params.brxm;
					this.cardInfo.xzmc = this.$route.params.xzmc;
					this.cardInfo.id = this.$route.params.pd;
					setTimeout(() => {
						if (this.cardInfo.id == "0") {
							this.isActive5 = "p1";
						} else {
							this.isActive5 = "p2";
						}
					}, 100)
				}
			}
		},

	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.van-cell {
		width: 90%;
		padding: 0;
	}

	.van-tabbar-item {
		height: 0px;
	}

	.div {
		text-align: left;
		font-size: 14px;
		margin-top: 10px
	}

	.div {
		text-align: left;
		font-size: 14px;
		margin-top: -10px
	}

	.button3 {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		cursor: not-allowed;
		opacity: 0.5;
		box-shadow: 0 0 5px 0 #DEDEDE;
		border-left: none;
		border-top: none;
		border-bottom: none;
	}

	.button4 {
		border: none;
	}

	.button1 {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		border: none;
	}

	.button2 {

		cursor: not-allowed;
		opacity: 0.5;

		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 2px;
		box-shadow: 0 0 5px 0 #DEDEDE;
		border: none;

	}

	.van-badge__wrapper {
		display: block;
		color: @black;
	}

	.infoContainer {
		background-color: @white;
		border-radius: 8px;
		box-shadow: 0px 0px 5px 0px @gray-4;
		margin-bottom: 15px;
	}

	.left {
		text-align: right;
		display: inline-block;
		box-sizing: border-box;
		padding-right: 5px;
	}

	.right {
		display: inline-block;
		box-sizing: border-box;
		padding-left: 5px;
	}

	.van-cell {
		background-color: #F6F6F6;
		color: #000;
		width: 90%;
		margin-left: 5%;
		line-height: 14px;

		height: 18px;
	}

	.van-cell-group {
		padding-left: 1%;
		padding-top: 0px;
	}

	element.style {}

	.van-cell__value {
		text-align: left;
		min-width: 70%;
		height: 14px;
		color: green;
	}

	.p1 {
		display: none;

	}

	.p2 {
		display: inherit;
	}

	.van-cell::after {
		border-bottom: none;
		border-top: none;
	}

	.van-hairline--top-bottom::after,
	.van-hairline-unset--top-bottom::after {
		border-width: 0;
	}

	.infoContainer {
		padding-bottom: 10px;

		p {
			margin: 0;
		}

		.borderColor {
			background-color: #f6f6f6;
			border-radius: 8px;
			padding: 10px;
			margin: 0 10px;

			.leftArea {
				width: 85%;
				display: inline-block;

			}

			.rightArea {
				width: 15%;
				display: inline-block;
				vertical-align: top;

				p {
					float: right;
				}

				.van-icon {
					float: right;
					margin-top: 40px;
				}
			}
		}

		.fontmd {
			font-size: @font-size-md;
			width: 300px;
		}

		.fontsm {
			font-size: @font-size-sm;
		}
	}


	.van-tabbar-item__text {
		height: 200px;
	}

	.cardInfo {
		font-size: @font-size-sm;

		span {
			margin: 3px;

			a {
				color: @black;
			}
		}

		.van-icon {
			position: relative;
			top: 4px;
		}
	}

	.bottomAdd {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		text-align: center;
		background: white;

		p {
			color: @black;
			margin: 0;
			font-size: @font-size-sm;
		}
	}
</style>
